
export  const l_domain="https://content.dubbaka.com"; // no trailing slash
export  const l_sitename="Nikhil Dubbaka";
export  const l_twitter_handle="TechNikh";
export  const l_facebook_handle="";

export  const l_sidebar_block1_title="Drupal Memes";
export  const l_sidebar_block1_tid=26;

export  const l_sidebar_block2_title="Drupal Quotes";
export  const l_sidebar_block2_tid=31;

export  const l_footer_block1_title="Drupal Quotes";
export  const l_footer_block1_tid=31;

export  const l_footer_block2_title="Drupal Memes";
export  const l_footer_block2_tid=26;

export  const l_secondary_menu_title="Drupal 8";
export  const l_secondary_menu_tid=22;

export  const l_homepage_featured_title="Drupal Quotes";
export  const l_homepage_featured_tid=31;

export const l_default_image=""
